<template>
  <div
    class="mt-10 w-full md:w-9/12 font-proximaMedium md:border-l-2 border-lightGrey40"
  >
    <ul class="text-center flex items-center justify-evenly flex-wrap">
      <li
        class="mb-2 md:mb-0 w-full md:w-4/12 text-xl font-bold md:border-r border-lightGrey40"
      >
        Matching Result: {{ data.length }}
      </li>
      <li
        class="block w-4/12 md:w-2/12 text-sm md:text-xl md:font-bold border-r border-lightGrey40"
      >
        Availability
      </li>
      <li
        class="block w-4/12 md:w-3/12 text-sm md:text-xl md:font-bold border-r border-lightGrey40"
      >
        Next Litter
      </li>
      <li class="block w-4/12 md:w-3/12 text-sm md:text-xl md:font-bold">
        Price Range
      </li>
    </ul>
    <div class="md:ml-2 mt-2">
      <!-- <span v-if="!data">No data</span> -->
      <ResultListEach v-for="(d, i) in data" :key="i" :data="d" />
      <!--    <ResultListEach /> -->
    </div>
  </div>
</template>

<script>
import ResultListEach from "./ResultListEach.vue";

export default {
  props: ["data"],
  components: {
    ResultListEach,
  },
};
</script>

<style></style>
