<template>
  <div class="my-6">
    <ResultSection />
  </div>
</template>

<script>
import ResultSection from "../components/breederdirectory/result/ResultSection.vue";

export default {
  components: {
    ResultSection,
  },
};
</script>